body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
	
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(216, 216, 216); 
  border-radius: 8px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */



.snap-x-scroll{
  scroll-snap-type: inline mandatory;
  scroll-behavior: smooth;
}

.snap-x-scroll > *{
  scroll-snap-align: center;
}


@font-face {
	font-family: 'Rhythem';
	src: url('./util/font/RhythemSignature-GO8vg.ttf');


	font-family: 'Cormorant Garamond', serif;
	font-family: 'Oswald', sans-serif;
	font-family: 'Sassy Frass', cursive;
}


.Garamond{
	font-family: 'Cormorant Garamond', serif;
	font-weight: 100;
}



#slider a {
	/* position: relative; */
	/* width: 500%; */
	margin: 0;
	left: 0;
	animation: 30s slider infinite;
}


@keyframes slider {
	0% {
		transform: translateX(0);
	}
	5% {
		transform: translateX(0);
	}
	15.5% {
		transform: translateX(-100%);
	}
	27% {
		transform: translateX(-200%);
	}
	40.5% {
		transform: translateX(-300%);
	}
	54% {
		transform: translateX(-400%);
	}
	65.5% {
		transform: translateX(-500%);
	}
	81% {
		transform: translateX(-600%);
	}
	100% {
		transform: translateX(0%);
	}
}

/* .scroll-container, .scroll-area{

} */

.scroll-container{
	/* overflow: auto; */
	scroll-snap-type: y mandatory;
  	scroll-behavior: smooth;

}

.scroll-area{
	scroll-snap-align: center;
}





