:root{
  
  --border-color: #adadad;
  --border-color-light: #E2E2E2;

  --ligh-yellow-color: #FE9B1D;
  /* --darker-yellow-color: #ffb048; */

  --highlight: #9aa506;
  /* --highlight: #B2804F;   */
  --highlight-2: #d8daba;

  --banner-studio-color-theme: #9aa506;

  --bg-color-light:#f5f7f8;
  --bg-color-mid:#1B548A;
  --bg-color-dark:#124375;


  --font-color-main: #073D60;
  --font-color-light:#DFE5EB;
  /* --font-color-mid:; */
  --font-color-dark: #3a464e;


  --space-thin: 2rem;
  --space-small: 4rem;
  --space-wide: 11rem;


  --font-tiny: .6rem;
  --font-small: .8rem;
  --font-medium: 1rem;
  --font-large: 1.5rem;
  --font-xlarge: 2rem;
  
  
}

*{
  color: black;
  letter-spacing: 2px;
}

html{
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
}

.bg-color-light{background-color: var(--bg-color-light);}
.bg-color-mid{background-color: var(--bg-color-mid);}
.bg-color-dark{background-color: var(--bg-color-dark);}

.vh100{height: 100vh;}
.vh80{height: 80vh;}
.vh60{height: 60vh;}
.vh10{height: 10vh;}

.width50{width: 50%;}

.width60{width: 60%;}
.width40{width: 40%; }

.height100dvh{
  height: 100dvh;
}


/* FLEX STYLINGS */
.flex{ display: flex;}
.flex-column{flex-direction: column;}
.flex-row{flex-direction: row;}
.flex-row-reverse{flex-direction: row-reverse;}

.flex-column-on-desktop-row-on-mobile{flex-direction: column;}
.flex-row-on-desktop-column-on-mobile{flex-direction: row;}
.flex-align-center{align-items: center;}
.flex-justify-center{justify-content: center;}

.flex-center{
  align-items: center;
  justify-content: center;
}

.space-between{justify-content: space-between;}
/* FLEX STYLINGS */

/* PADDINGS */
.padding-0-1{padding: 0 1rem}

.padding-1-2{padding:1rem var(--space-thin)}

.padding-2-2{padding: var(--space-thin)}
.padding-0-2{padding: 0 var(--space-thin)}

.padding-2-4{padding: var(--space-thin) var(--space-small);}
.padding-0-4{padding: 0 var(--space-small);}

.padding-2-6{padding: var(--space-thin) var(--space-wide);}
.padding-6-6{padding: var(--space-wide);}

.padding-0-6{padding: 0 var(--space-wide);}

.margin-bottom-1rem{margin-bottom: 1rem;}
/* PADDINGS */

/* FONT SIZINGS */
.font-tiny{font-size: var(--font-tiny);}
.font-small{font-size: var(--font-small);}
.font-medium{font-size: var(--font-medium);}
.font-large{font-size: var(--font-large);}
.font-xlarge{font-size: var(--font-xlarge);}
/* FONT SIZINGS */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  /* background-color: rgb(255, 200, 200); */
}

.white{color: white;}

.not-available{cursor: not-allowed;}

.active{
  color: var(--highlight);
}

a{
  text-decoration: none;
}


/* .submit{
  position: absolute;
  z-index: 10;
  right: 11rem;
  bottom: 6rem;
  padding:.5rem 1rem;
  border: 1px solid var(--highlight);
  color: var(--highlight);
} */


.header{
	position: relative;
  z-index: 10;
  /* height: 100dvh; */
  /* background-color: #5d6f7c; */
  background-color: transparent;
  /* border: #001a33 1px solid; */
  /* height:5rem; */
}

.uppercase{
  text-transform: uppercase;
}

.logo{
  font-family: 'Rhythem';
  font-size: 4rem;
  line-height: 2rem;
  width: 100%;

  /* background-color: #073D60; */
  /* position: absolute; */

}
.logo>a{
  color: var(--highlight);
}

nav{
  
  background-color: transparent;
  
  /* background-color: red; */
}

.nav{
  transition: .5s;
  text-shadow: 1px 1px #ffffff;
  padding: .3rem 0; 
  margin: 0 1rem;
}



.nav:hover{
  color: rgb(148, 148, 148);
  border-bottom: 1px solid var(--highlight);
}

.menu{
  position: fixed;
  top: 2rem;
  left: 2rem;
}

.SideNav{
  position: fixed;
  top: 2rem;
  right: 2rem;
}

.socials{
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 11;
}

.scroll{
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  transform: rotate(90deg);
  text-shadow: 1px 1px #ffffff;
  /* font-size: 2rem; */
  /* font-weight: 100; */
  color: var(--highlight);

}


.social-icons {
  margin-right: 2.5rem;
 
}

.social-icons *{
  /* color: #5d6f7c; */
  width: 3rem;
  padding: .5rem 0 ;
  color: var(--highlight);
  
  /* background-color: red; */
}

.social-icons:hover *{
  color: var(--highlight);
}




footer{
  height: 3rem;
}


.AdSpaceYo{
  position: absolute;
  right: 6rem;
  top: 8rem;
  z-index: -1;
  /* background-color: #124375; */
  width: 18vw;
  min-height: 35rem;
}

.banner{
  width: 100%;
  height: 30rem;
}

/* .banner > img{
  width: 100%;

  height: 30rem;
} */

.NotFound{
  min-height: 70vh;
}


/*//////////////*/
/* GALLERY PAGE */
/*//////////////*/

.ImagesComponenet-container{
  /* background-color: red; */
  width: 60dvw;

}


.ImagesComponenet-container >h1{
  text-align: center;
}


.ImagesComponenet-profile{
  height: 1.5rem;
  width: 1.5rem;
  margin-right: .8rem;
  border-radius: 5rem;
}

.ImagesComponenet-studio{
  text-align: center;
  font-size: var(--font-small);
  font-weight: 400;
  letter-spacing: 1px;
}

.ImagesComponenet-h2{
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.ImagesComponenet{
  columns: 15rem;
  /* background-color: #124375; */
}

.ImagesComponenet > img{
  width: 100%;
  margin: .5rem 0;
}

.ImagesComponenet-view-more{
  /* background-color: var(--highlight); */
  /* padding: 1rem 22.308rem; */
  /* margin: 1rem 0; */
  /* background-color: red; */
  width: 60rem;
  text-transform: uppercase;
  border: 1px solid black;
  transition: .2;

}

.ImagesComponenet-view-more:hover{
  color: var(--highlight);
  border-color: var(--highlight);
}



.width62{
  width: 62.2%;
}







/*/////////////*/
/* CAREER PAGE */
/*/////////////*/


.Gallery{
  position: relative;
  z-index: -1;
  min-height: 80vh;
  /* overflow-x: hidden; */
}

/* .scroll{
    margin-top: 13rem;
} */

.card-section{
  
  overflow-x: hidden;
  width: 100vw;
  
}


.studio-names{
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  font-size: var(--font-tiny);
}

.studio-name{

  color: rgb(117, 117, 117);
}


.GalleryCardSlider{
  /* background-color:  red; */
  width: 100vw;
  height: 25rem;
}

.GalleryCardSlider > img{
  transition: .8s;
  object-fit: cover;
  object-position: center;
  box-shadow:  0 20px 20px #c5c5c5
}

.GalleryCardSlider > img:hover{
  transform: scale(1.01);
}

.GalleryCardSection{
  /* columns: 3 20rem; */
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
}

.GalleryCard{
  box-shadow: 5px 5px 5px 1px rgba(170, 170, 170, 0.2);
}

.gallery-card-img-frame{
  position: relative;
  width: 100%;
  overflow: hidden;
  /* height: 15rem; */
}

.gallery-card-img-date{
  position: absolute;
  background-color: white;
  top: 1rem;
  z-index: 10;
  left: 1rem;
  text-align: center;
  text-transform: uppercase;
  padding: .4rem .7rem;
  font-weight: 700;
}

.gallery-card-img{
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  display: block;
  transition: .3s;
}

.GalleryCard:hover .gallery-card-img{
  transform: scale(1.01);
}

.gallery-card-info{
  text-align: center;
  /* border: 1px solid rgb(238, 238, 238); */
  padding: 1rem;
}

.gallery-card-title{
  font-weight: 400;
  letter-spacing: 1px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  letter-spacing: 1px;
}

.author-profile{
  background-color: rgb(194, 194, 194);
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin: 0 .3rem;
}

.gallery-card-p{
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  letter-spacing: 1px;
}

.GalleryPost{
  margin-top: 6rem;
  /* background-color: #073D60; */
}





/*/////////////*/
/* CAREER PAGE */
/*/////////////*/
.career-hiring-section{
  min-height: 60vh;
}


.career-hiring-section>div{
  /* background-color: aquamarine; */

  width: auto;
}

.career-hiring-section>.career-img-div{
  margin-left: var(--space-wide);
  /* overflow: hidden; */
  /* background-color: aquamarine; */
  margin-right: 3rem;
}


.career-hiring-section>.career-img-div>img{
  height: 20rem;
  /* aspect-ratio: 3/2;
  object-fit: cover; */
}

.career-hiring-section > .career-hiring{
  padding-right: var(--space-wide);
}

.career-heading{
  font-size: var(--font-medum);
  margin: 0;
  font-weight: 500;
}

.career-p{
  font-size: var(--font-small);
  font-weight: 400;
  margin-bottom: 1rem;
  /* letter-spacing: normal; */
}

.career-p a{
  color:  var(--highlight);
}

.apply-here{
  text-decoration: underline;
}


/*/////////////*/
/* CAREER PAGE */
/*/////////////*/

/*/////////////*/
/*  BLOG PAGE  */
/*/////////////*/

/* .Blogs{
  margin-top: 8rem;
  background-color: #073D60;
} */

.BlogSection{
  /* background-color: #9aa506; */
  width: 60dvw;
}

.BlogSection > section{
  height: 100dvh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* padding-top: 8rem;
  border: 2px solid red; */
}


.BlogSection > section > div{
  min-height: 60%;
  /* background-color: #adadad; */
  width: 100%;
}


.BlogSectionHeroContetn{
  background-image: url('https://images.unsplash.com/photo-1525441273400-056e9c7517b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  box-shadow: inset 0px -6rem 10rem -1rem #3a464e,
  inset 1rem -7rem 10rem -2rem #3a464e;
}

.BlogSectionHeroContetn-author{
  padding: 1rem 2rem;
}

.BlogSectionHeroContetn-author * {
  color: var(--font-color-light);
}

.BlogSectionTopFive-container{
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.BlogSectionTopFive-container::before{
  content: '-Latest blogs-';
  position: absolute;
  transform: rotate(-90deg);
  left: -4rem;
  top: 40%;
  /* transform:translateY(50%); */
  /* bottom: 0; */

}

.BlogSectionTopFive-container > .BlogSectionTopFive-content-container{
  height: 22rem;
  display: flex;
}

.BlogSectionTopFive-content{
  /* background-color: #5d6f7c; */
  /* border: 1px solid red; */
  height: 100%;
  width: 50%;
  padding: 1rem;
}


.BlogSectionTopFive-large-content:hover .BlogSectionTopFive-large-content-cover-img{
  transform: scale(1.05);
}

.BlogSectionTopFive-large-content-cover{
  overflow: hidden;
  width: 100%;
  height: 50%;
}
.BlogSectionTopFive-large-content-cover-img{
  transition: .3s;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.BlogSectionTopFive-large-content-author > div {
  display: flex;
  align-items: center;
}

.BlogSectionTopFive-large-content-author > div  *{
  height: .8rem;
  margin: .8rem 1rem 0 0;
  padding: 0;
  font-size: var(--font-tiny);
  /* background-color: red; */
}

.BlogSectionTopFive-large-content-author > div > img{
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  /* margin-left: 1rem; */
}

.BlogSectionTopFive-large-content-author > h2 {
  font-size: var(--font-medium);
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.BlogSectionTopFive-large-content-author > p {
  font-size: var(--font-small);
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .BlogSectionTopFive-small-content{
  columns: 1;
} */

.BlogSection-small-content > a :hover .BlogSection-small-content-img{
  transform: scale(1.05);
}

.BlogSection-small-content-containers{
  display: flex;
  /* background-color: green; */

}


.BlogSection-small-content-img-container{
  /* background-color: red; */
  overflow: hidden;
  min-width: 7.5rem;
  margin-right: 1rem;
}

.BlogSection-small-content-img-container > img{
  transition: .3s;
  height: 5rem;
  
}

.BlogSection-small-content-author-and-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.BlogSection-small-content-author-and-title > h3{
  font-size: var(--font-small);
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  /* background-color: #c5c5c5; */
}

.BlogSection-small-content-author > div {
  display: flex;
  align-items: center;
  /* background-color: #9aa506; */
}

.BlogSection-small-content-author > div  *{
  height: .8rem;
  margin: 0 .5rem 0 0;
  padding: 0;
  font-size: var(--font-tiny);
  /* background-color: red; */
}

.BlogSection-small-content-author > div > img{
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}

.small-contetn-author-profile-pic{
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}

.BlogSection-content-view-more{
  /* background-color: #9aa506; */
  transition: .2s;
  border: 1px solid #9aa506;
  color: #9aa506;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlogSection-content-view-more:hover{
  background-color: #9aa506;
  color: white;
}




.BlogSectionSponsor{
  position: relative;
  height: 5rem;
  margin-top: 4rem;
  background-color: #5d6f7c;
}

.BlogSectionSponsor::before{
  position: absolute;
  content: '-SPONSORED AD-';
  top: -1.3rem;
  right: 50%;
  transform: translateX(50%);
  font-size: var(--font-tiny);
}

.BlogSectionOtherTopic-large-content-container{
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  padding: 1rem 1rem 0;
}



.BlogSectionOtherTopic-large-content-container > a{
  /* background-color: red; */
  max-height: 18rem;
}

.BlogSectionOtherTopic-large-content-container > a:hover .BlogSectionTopFive-large-content-cover-img{
  transform: scale(1.05);
}


.BlogSectionOtherTopic-large-content-container > a > .BlogSectionTopFive-large-content-cover{
  height: 60%;
}

.BlogSectionOtherTopic-large-content-container > a > div > h2{
  -webkit-line-clamp: 1;
  margin: 1rem 0 0;
}

.BlogSectionOtherTopic-large-content-container > a > div > p{
  -webkit-line-clamp: 2;
  margin: .2rem 0 0;
  
}


.BlogSectionOtherTopic-large-content-container > a > .BlogSectionTopFive-large-content-author > div {
  display: flex;
  align-items: center;
  /* background-color: #073D60; */
}

.BlogSectionOtherTopic-large-content-container > a > .BlogSectionTopFive-large-content-author > div *{
  margin-top: 0;
}


.BlogSectionOtherTopic-small-content-container{
  display: grid;
  grid-template-columns: auto auto;
  padding: 1rem;
  /* background-color: red; */
}


.BlogSectionOtherTopic-view-more > div{
  margin: .5rem 0 0;
}

.BlogSectionOtherTopic-small-content-container{
  margin: 0;
  padding-bottom: 0;
}

/* .BlogSectionOtherTopic-small-content-container > a{
  background-color: #124375;
} */




/*/////////////*/
/*  BLOG PAGE  */
/*/////////////*/


@media screen and (max-width: 360px) {
  :root{  
  
    --space-thin: 2rem;
    --space-small: 4rem;
    --space-wide: 1rem;
  
  
    --font-tiny: .5rem;
    --font-small: .8rem;
    --font-medium: 1rem;
    --font-large: 1.5rem;
    --font-xlarge: 2rem;
    
  }


  .flex-row-on-desktop-column-on-mobile{flex-direction: column;}

}